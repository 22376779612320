import { useState } from "react";
import { motion } from "framer-motion";
import PopUpTile from "./PopUpTile";

function DashboardButton({ index, descp, title, data, setBg }) {
  const [popup, setPopup] = useState(false);

  const variants = {
    0: "workBg col-span-3 row-span-4 ",
    1: "communityBg col-span-6 row-span-4 col-start-4 ",
    2: "awardsBg col-span-3 row-span-4 row-start-5 ",
    3: "mentorshipBg col-span-3 row-span-4 col-start-4 row-start-5 ",
    4: "projectBg col-span-3 row-span-4 col-start-7 row-start-5 ",
  };
  const variantsBg = {
    0: "workBg",
    1: "communityBg",
    2: "awardsBg",
    3: "mentorshipBg",
    4: "projectBg",
  };

  return (
    <>
      <button
        title={title}
        className={`relative small:gap-10 w-full h-[42vh] small:self-center bg-noise bg-repeat bg-contain  ${variants[index]} text-chalk  select-none cursor-pointer small:rounded-xl rounded-3xl z-10`}
        onClick={() => {
          setBg({
            image: "url(./others/bannerDark.svg",
            color: "#1F1F1F",
          });
          return setPopup(true);
        }}
      >
        <div
          className={`relative text-left w-full h-full  p-10 small:p-4 small:hover:p-4 hover:p-12 transition-all ease-in-out duration-300 flex flex-col items-end justify-between rounded-3xl bg-noise small:rounded-xl z-0`}
        >
          <img alt={title} loading="lazy" src="./icons/arrowWhite.svg" />

          <h3 className="text-[3vw] self-start small:text-[3vh]">{title}</h3>
        </div>
      </button>

      <>
        {popup && (
          <div className="absolute z-50 flex flex-col  items-center w-full bg-charcoal h-[90vh] small:h-[92vh]  p-4 small:p-0 ">
            <motion.div
              className="rounded-3xl small:rounded-2xl bg-ivory w-[90%] small:w-[90%] small:h-[95%] pt-20 px-0 h-auto overflow-y-scroll overflow-x-clip relative"
              initial={{ opacity: 0.01 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: 0.5,
                ease: "easeOut",
              }}
            >
              {data.map((item, id) => (
                <PopUpTile
                  key={id}
                  title={item?.title}
                  descriptions={item?.descriptions}
                  item={item}
                  bg={variantsBg[index]}
                />
              ))}
              <button
                onClick={() => {
                  setBg({
                    image: "url(./others/bannerLight.svg)",
                    color: "#F4F9FF",
                  });
                  return setPopup(false);
                }}
                title={"Dashboard"}
                className="absolute top-10 right-10 small:right-5 small:top-10 z-40"
              >
                <img
                  className="small:w-[50%]"
                  loading="lazy"
                  alt="Back to Dashboard"
                  src="./icons/cross.svg"
                />
              </button>
            </motion.div>
          </div>
        )}
      </>
    </>
  );
}

export default DashboardButton;
