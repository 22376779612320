import { motion } from "framer-motion";
import { useState, useEffect } from "react";

function ScrollBanner({ bg, bgColor, bottom }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 10);
    return () => clearInterval(intervalId);
  }, [progress]);

  const variants = {
    0: "absolute -bottom-20",
    1: "relative",
  };

  return (
    <motion.p
      className={`w-full h-[6rem] ${bottom ? variants[0] : variants[1]} bg-repeat-x bg-center bg-cover`}
      style={{
        backgroundPositionX: progress + "px",
      }}
      animate={{
        backgroundImage: bg,
        backgroundColor: bgColor,
      }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
    ></motion.p>
  );
}

export default ScrollBanner;
