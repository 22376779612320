import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import Stars from "./Stars";

const options = [
  "Do you enjoy crafting user experiences or improving server performance?",
  "Are you drawn to creative development or data management?",
  "Do you prefer building apps from scratch or refining systems?",
  "Are you excited by frontend design or backend complexities?",
];

function Loading({ progress, setProgress }) {
  const quote = useRef(options[Math.floor(Math.random() * 4)]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progress >= 100) {
        clearInterval(intervalId);
      } else setProgress((prevProgress) => prevProgress + 1);
    }, 20);

    return () => clearInterval(intervalId);
  }, [progress]);

  return (
    <div
      key="loader"
      className="fixed top-0 w-screen h-screen bg-[black] loader text-chalk z-50 cursor-wait"
    >
      <motion.div
        className="w-full h-full overflow-hidden bg-[black] flex flex-col justify-center items-center z-50"
        initial={{ opacity: 0.01 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
        }}
      >
        <p
          style={{
            fontFamily: "Luimp",
            fontWeight: 600,
          }}
          className="text-4xl small:text-left small:text-xl my-2 w-[70%] tracking-widest z-20"
        >
          {progress < 100
            ? progress < 10
              ? `00${progress}`
              : `0${progress}`
            : `${progress}`}
        </p>

        <p
          style={{
            fontFamily: "Luimp",
            fontWeight: 300,
          }}
          className="w-[70%] text-[3vw] small:text-[3vh] tracking-tightest text-left z-20"
        >
          {quote.current}
        </p>

        <Stars />
      </motion.div>
    </div>
  );
}

export default Loading;
