import { useState, useEffect, Suspense } from "react";
import { pageMinDelay } from "../utils/pageMinDelay";

import Loading from "../components/Loader/index";
import Footer from "../components/Footer";
import { AnimatePresence } from "framer-motion";
import { ReactLenis } from "@studio-freight/react-lenis";
const Home = pageMinDelay(() => import("./Home"), 2000);

function App() {
  const [urlPath, setUrlPath] = useState(
    window.location.pathname.slice(1).toLowerCase(),
  );
  const [progress, setProgress] = useState(0);

  useEffect(
    () => setUrlPath(window.location.pathname.slice(1).toLowerCase()),
    [],
  );

  return (
    <ReactLenis
      options={{
        smoothWheel: true,
        duration: 5,
        lerp: 0.03,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      }}
      className="home"
      root={true}
    >
      <AnimatePresence>
        {progress < 100 && (
          <Loading progress={progress} setProgress={setProgress} />
        )}
        <Home />
        <Footer />

        <aside key="psuedo-loader" className="loader"></aside>
      </AnimatePresence>
    </ReactLenis>
  );
}

export default App;
