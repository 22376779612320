import { createRoot } from "react-dom/client";

import App from "./pages/App";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter, Routes, Route, Link } from "react-router";

import "./styles/main.min.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="dashboard" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  </>,
);
