import { Link } from "react-router";

function BottomBar() {
  return (
    <div className="fixed z-50 bg-[#161616] rounded-md bottom-10   p-1.5 z-20 flex justify-center items-center gap-2 transition-transform ease-in-out delay-150 ">
      <Link
        className="opacity-55 hover:opacity-100 transition-all"
        to="/"
        title="Home"
      >
        <img
          fetchPriority="high"
          className="h-6"
          src="logo192.png"
          alt="Home"
        />
      </Link>
      <Link
        className="opacity-55 hover:opacity-100 transition-all "
        to="/dashboard"
        title="Dashboard"
      >
        <img
          fetchPriority="high"
          className="h-6"
          src="/others/dashboard.svg"
          alt="Dashboard"
        />
      </Link>
    </div>
  );
}

export default BottomBar;
